import { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import Arrow from "./Icons/Arrow";
import ArrowDown from "./Icons/ArrowDown";

const languages = {
  en: "EN",
  it: "IT",
};

function LanguageSwitch({ currentPath }: { currentPath: string }) {
  const [language, setLanguage] = useState<string | null>(null); // No default language initially
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const savedLanguage = window.localStorage.getItem("preferredLanguage");
      const defaultLang = currentPath.startsWith("/en") ? "en" : "it";
      const activeLanguage = savedLanguage || defaultLang;

      setLanguage(activeLanguage);

      // Redirect if the saved language doesn't match the current URL path
      if (!currentPath.startsWith(`/${activeLanguage}`)) {
        const newPath = currentPath.replace(/^\/(en|it)/, `/${activeLanguage}`);
        window.location.href = newPath;
      }
    }
  }, [currentPath]);

  useEffect(() => {
    if (language) {
      window.localStorage.setItem("preferredLanguage", language);
    }
  }, [language]);

  const switchLanguage = (lang: string) => {
    setLanguage(lang);
    const newPath = currentPath.replace(/^\/(en|it)/, `/${lang}`);
    window.location.href = newPath; // Redirect to the new language path
  };

  if (language === null) {
    return null; // Wait until language is set
  }

  return (
    <>
      <div className="tablet:hidden mobile:hidden">
        <DropdownMenu>
          <DropdownMenuTrigger className="outline-none">
            <img src={language === "it" ? "/it.png" : "/en.png"} alt={`flag-${language}`} />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="gap-2.5 px-2.5 py-5">
            {Object.entries(languages).map(([lang, label]) => (
              <DropdownMenuItem key={lang} onClick={() => switchLanguage(lang)}>
                <a href={`/${lang}/`} className="flex flex-row gap-2 items-center">
                  <img src={`/${lang}.png`} alt={`flag-${lang}`} />
                  <p className="text-xs font-medium">{label}</p>
                </a>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div className="w-full desktop:hidden laptop:hidden px-6">
        <button
          className="flex flex-row w-full justify-between px-2"
          onClick={() => setOpenMenu(!openMenu)}
        >
          <div className="flex flex-row items-center gap-2 text-primary-black font-medium text-sm">
            <img src={language === "it" ? "/it.png" : "/en.png"} alt={`flag-${language}`} />
            {language === "it" ? "IT" : "EN"}
          </div>

          {openMenu ? <ArrowDown size="18" /> : <Arrow size="18" />}
        </button>

        {openMenu && (
          <button
            className="flex flex-row justify-between w-full bg-secondary-200 p-2 gap-2 rounded-lg mt-4 px-2"
            onClick={() => {
              const newLang = language === "it" ? "en" : "it";
              setLanguage(newLang);
              setOpenMenu(false);
              const newPath = currentPath.replace(/^\/(en|it)/, `/${newLang}`);
              window.location.href = newPath;
            }}
          >
            <div className="flex flex-row items-center gap-2 text-primary-black font-medium text-sm">
              <img src={language === "it" ? "/en.png" : "/it.png"} alt={`flag-${language}`}/>
              {language === "it" ? "EN" : "IT"}
            </div>
          </button>
        )}
      </div>

    </>
  );
}

export default LanguageSwitch;
