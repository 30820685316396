interface ArrowProps {
  size?: string;
}

const Arrow = ({ size }: ArrowProps) => {
  return (
    <svg
      width={size || "10"}
      height={size || "16"}
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.2433 14.7371L8.89765 7.96471C8.92983 7.93616 8.95559 7.9011 8.97323 7.86186C8.99088 7.82262 9 7.78009 9 7.73706C9 7.69404 8.99088 7.6515 8.97323 7.61226C8.95559 7.57302 8.92983 7.53796 8.89765 7.50941L1.2433 0.737062"
        stroke="#111827"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Arrow;
